/**
 * Site Header
 */
.site-header {
	// border-bottom: 1px solid $color-header-border;
	z-index: 100;
	font-family: $display-font;
	background: -webkit-linear-gradient(
		rgba(255, 255, 255, 80),
		rgba(255, 255, 255, 0)
	);
	background: -o-linear-gradient(
		rgba(255, 255, 255, 80),
		rgba(255, 255, 255, 0)
	);
	background: linear-gradient(
		rgba(255, 255, 255, 80),
		rgba(255, 255, 255, 0)
	);
	position: sticky;
	top: 0;
	width: 100%;
	transition: background 1s ease;

	@include media-breakpoint-up(md) {
		&.scrolled {
			background: $color-nav-mobile-bg;
			filter: drop-shadow(0 .5em 1em $color-neutral-dark-sect-bg);
			.navbar__logo img {
				height: 4rem;
				transition: height 1s ease;
			}
		}
	}
}

.navbar__logo,
.navbar__title {
	text-decoration: none;
}

.navbar__logo {
	img {
		display: block;
		max-height: 6em;
	}
}

.navbar__mobile-logo .navbar__logo {
	img {
		display: block;
		max-height: 2rem;
		@include media-breakpoint-up(md) {
			display: none;
		}
	}
	a {
		@include media-breakpoint-up(md) {
			display: none;
		}
	}
}

.navbar__menu-btn,
.navbar__close-btn,
.navbar__mobile-placeholder {
	font-size: 1.11111em;
}

.navbar__mobile-placeholder {
	svg {
		visibility: hidden;
	}

	@include media-breakpoint-up(md) {
		display: none;
	}
}

.nav-overlay {
	background: $color-nav-mobile-overlay;
	height: 100vh;
	left: 0;
	opacity: 0;
	position: absolute;
	top: 0;
	transition: opacity .25s, visibility 0s .25s;
	visibility: hidden;
	width: 80%;
}

@include media-breakpoint-down(md) {
	.site-header {
		background: $color-primary;
		box-shadow: 0 0.5em 1em 0 $color-neutral-dark-sect-bg;
		// filter: drop-shadow(0 .5em 1em $color-neutral-dark-sect-bg);
	}
	.navbar__container {
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		background-color: $color-nav-mobile-bg;
		bottom: 0;
		right: -100%;
		max-width: container-max-width(small);
		-webkit-overflow-scrolling: touch;
		position: fixed;
		top: 0;
		transition: right .3s cubic-bezier(.65,.05,.36,1), visibility 0s .3s;
		visibility: hidden;
		width: 80%;
		z-index: 999;
	}

	.navbar__scroller {
		height: 100%;
		overflow-y: auto;
		position: relative;
	}

	.navbar__inner {
		padding: 1.66667rem;
		text-align: right;

		.navbar__branding {
			@include media-breakpoint-down(md) {
				order: -1;
			}
		}
	}

	.navbar__menu {
		border-top: 1px solid $color-nav-mobile-border;
		margin-top: 1.11111rem;
		padding-top: 1.11111rem;
		text-align: left;

		.menu__item {
			margin-bottom: 0.55556rem;

			& + .menu__item-btn {
				margin-top: 0.83333rem;
			}

		}

		a {
			&:not(.btn) {
				display: block;
				padding: 0.55556rem 0;
			}
		}

		.btn {
			padding-left: 0.75rem;
			padding-right: 0.75rem;
			width: 100%;
		}

		@include media-breakpoint-down(md) {
			#Primary-Menu {
				order: 2;
			}

			#Secondary-Menu {
				order: 3;
			}
		}
	}

	.js-nav-open {
		overflow: hidden;

		.nav-overlay {
				opacity: 1;
				transition: opacity .25s;
				visibility: visible;
				// z-index: 998;
		}

		.navbar__container {
			right: 0;
			transition: right .3s ease;
			visibility: visible;
		}
	}
}

@include media-breakpoint-down(sm) {
	.navbar__inner {
		padding: 1.11111rem 0.83333rem;
	}
}

@include media-breakpoint-up(md) {
	.navbar__menu-btn,
	.navbar__close-btn {
		display: none;
	}

	.navbar__menu {
		.menu {
			width: 40%;

			&:last-child {
				// margin-left: auto;
			}
		}

		a:not(.btn) {
			&:after {
				background: $color-nav-link-border;
				content: "";
				position: absolute;
				transition: .3s ease-in-out;
			}
		}

		a:not(.btn):hover,
		.is-active a {
			text-decoration: none;
		}

		.btn--primary,
		.btn--secondary {
			font-size: 0.83333em;
			min-width: 0;
			padding: 0.575em 1.33333em;
		}
	}
}

.style-minimal,
.style-classic {
	@include media-breakpoint-up(md) {
		.navbar__menu {
			a:not(.btn) {
				&:after {
					bottom: -2px;
					height: 1px;
					left: 0;
					width: 0;
				}
			}
			a:not(.btn):hover,
			.is-active a {
				&:after {
					width: 100%;
				}
			}
		}
	}
}

.style-bold {
	.site-header {
		border-bottom-width: 2px;

		.btn--primary,
		.btn--secondary {
			box-shadow: none !important;
			-webkit-transform: none !important;
			transform: none !important;

			&:hover,
			&:focus,
			&:active {
				opacity: .85;
			}
		}
	}

	@include media-breakpoint-up(md) {
		.navbar__menu {
			a:not(.btn) {
				&:after {
					bottom: -6px;
					height: 2px;
					left: 0;
					opacity: 0;
					right: 0;
				}
			}

			a:not(.btn):hover,
			.is-active a {
				&:after {
					bottom: -2px;
					opacity: 1;
				}
			}
		}
	}

	@include media-breakpoint-down(md) {
		.navbar__menu {
			border-top-width: 2px;
		}
	}
}

@each $style in map-keys($theme-styles) {
	$style-suffix: "#{$style}";
	$colors: map-deep-get($theme-styles, $style, "colors");
	@each $color, $value in $colors {
		$color-suffix: "#{$color}";

		.style-#{$style-suffix} {
			&.palette-#{$color-suffix} {
				@include media-breakpoint-up(md) {
					.navbar__menu {
						a:not(.btn) {
							&:after {
								background: $value;
							}
						}
					}
				}

			}
		}
	}
}
