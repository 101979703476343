img.circle {
  position: absolute;
  border-radius: 100%;
}

.img_container {
  position: relative;
  padding-bottom: 100%;
}

img.border__yellow {
  border: 3px solid $color-primary;
}
