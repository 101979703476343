/**
 * Light/dark mode
 */
.mode-light {
	&.style-classic {
		.card--highlight {
			box-shadow: none;

			&:hover {
				box-shadow: 0 2px 30px $color-classic-card-shadow;
			}
		}
	}

	&.style-bold {
		.card {
			border-color: $color-bold-card-border;
		}

		.site-header {
			border-color: $color-bold-header-border;
		}

		.site-footer__nav,
		.site-footer__info {
			border-color: $color-bold-footer-border;
		}

		.nav-overlay {
			background: $color-bold-nav-mobile-overlay;
		}

		@include media-breakpoint-down(md) {
			.navbar__menu {
				border-color: $color-bold-nav-mobile-border;
			}
		}

		&.layout-boxed {
			@media (min-width: 1401px) {
				.site {
					border-color: $color-bold-boxed-border;
				}
			}
		}

		.announcement-bar {
			background: $color-bold-anncmnt-bg;
			color: $color-bold-anncmnt-text;
		}

		.section {
			&.has-border {
				border-color: $color-bold-sect-border;
			}
		}
	}

	.bg-primary, .bg-neutral-light {
		color: $color-bold-sect-border;

		pre,
		th {
			background: 0;
		}

		.btn--primary {
			background-color: $color-bold-sect-border;
			color: $color-primary;
		}

		.btn--primary-outline {
			border: 1px solid $color-bold-sect-border;
			color: $color-bold-sect-border;
		}
	}

	.bg-secondary {
		pre {
			border-color: $color-secondary-sect-code-border;
		}

		table,
		th,
		td {
			border-color: $color-secondary-sect-table-border;
		}

		hr {
			background-color: $color-secondary-sect-hr-border;
		}
	}

	.bg-neutral-dark {
		color: $color-primary;

		pre,
		th {
			background: 0;
		}

		.btn--primary {
			background-color: $color-primary;
			color: $color-bold-sect-border;
		}
		.btn--primary-outline {
			border: 1px solid $color-primary;
			color: $color-primary;
		}
	}

	.bg-primary,
	.bg-secondary,
	.bg-neutral-dark,
	.bg-neutral-light {
		input[type="text"],
		input[type="password"],
		input[type="email"],
		input[type="tel"],
		input[type="number"],
		input[type="search"],
		input[type="url"],
		select,
		textarea {
			background-color: $color-sect-input-bg;
			border: 0;
		}
	}

	&.style-classic,
	&.style-bold {
		.bg-secondary {
			pre {
				background-color: $color-secondary-sect-code-bg;
			}

			th {
				background-color: $color-secondary-sect-table-bg;
			}
		}
	}

	.cell-12:nth-child(even) {
		.card {
			background: $color-card-bg-alt;
		}
	}

	.cell-12:nth-child(3), .cell-12:nth-child(7) {
		.card {
			background: $color-card-text;
      color: $color-card-bg;
		}
	}

	.card {
		background-color: $color-card-bg;
		color: $color-card-text;

		hr {
			background-color: $color-hr-border;
		}

		.btn--primary {
			background-color: $color-btn-bg;
			color: $color-btn-text;
		}

		input[type="text"],
		input[type="password"],
		input[type="email"],
		input[type="tel"],
		input[type="number"],
		input[type="search"],
		input[type="url"],
		select,
		textarea {
			background-color: $color-input-bg;
			border: 1px solid $color-input-border;
		}
	}

	&.style-classic {
		.card {
			pre {
				background-color: $color-code-bg;
				border-color: $color-code-border;
			}

			th {
				background-color: $color-table-bg;
			}

			table,
			th,
			td {
				border-color: $color-table-border;
			}

			input[type="text"],
			input[type="password"],
			input[type="email"],
			input[type="tel"],
			input[type="number"],
			input[type="search"],
			input[type="url"],
			select,
			textarea {
				background: $color-classic-input-bg;
			}
		}
	}

	&.style-bold {
		.card {
			pre {
				background-color: $color-bold-code-bg;
			}

			th {
				background-color: $color-bold-table-bg;
			}

			table,
			th,
			td {
				border-color: $color-table-border;
			}

			input[type="text"],
			input[type="password"],
			input[type="email"],
			input[type="tel"],
			input[type="number"],
			input[type="search"],
			input[type="url"],
			select,
			textarea {
				background: $color-bold-input-bg;
				border-color: transparent;
			}
		}
	}
}

.mode-dark {
	background-color: $color-dark-body-bg;
	color: $color-dark-body-text;

	&.layout-boxed {
		background-color: $color-dark-boxed-bg;

		.site {
			background-color: $color-dark-body-bg;
		}

		@media (min-width: 1401px) {
			.site {
				border-color: $color-dark-boxed-border;
			}
		}
	}

	mark,
	ins {
		background-color: $color-dark-highlight-bg;
		color: $color-dark-highlight-text;
	}

	pre {
		border-color: $color-dark-code-border;
	}

	&.style-classic {
		pre {
			background-color: $color-dark-code-bg;
		}
	}

	&.style-bold {
		pre {
			background-color: $color-dark-bold-code-bg;
		}
	}

	hr {
		background-color: $color-dark-hr-border;
	}

	table {
		border-color: $color-dark-table-border;
	}

	th,
	td {
		border-color: $color-dark-table-border;
	}

	&.style-classic {
		th {
			background-color: $color-dark-table-bg;
		}
	}

	&.style-bold {
		th {
			background-color: $color-dark-bold-table-bg;
		}
	}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	input[type="tel"],
	input[type="number"],
	input[type="search"],
	input[type="url"],
	select,
	textarea {
		background: $color-dark-input-bg;
		border-color: transparent;
		color: $color-dark-input-text;
	}

	.form-select {
		color: $color-dark-input-text;
	}

	&.style-bold {
		.btn--primary,
		.btn--secondary {
			box-shadow: 0.375em 0.375em 0 0 $color-dark-bold-btn-shadow;

			&:hover,
			&:focus,
			&:active {
				box-shadow: 0 0 0 0 $color-dark-bold-btn-shadow;
			}
		}
	}

	.menu__item {
		color: $color-dark-nav-link-text;
	}

	.site-header {
		border-color: $color-dark-header-border;
	}

	.nav-overlay {
		background: $color-dark-nav-mobile-overlay;
	}

	@include media-breakpoint-down(md) {
		.navbar__container {
			background-color: $color-dark-nav-mobile-bg;
		}

		.navbar__menu {
			border-color: $color-dark-nav-mobile-border;
		}
	}

	.site-footer__nav,
	.site-footer__info {
		border-color: $color-dark-footer-border;
	}

	.announcement-bar {
		background: $color-dark-anncmnt-bg;
		position: relative;
	}

	.section {
		&.has-border {
			border-color: $color-dark-sect-border;
		}
	}

	.bg-primary {
		pre,
		th {
			background: 0;
		}

		pre,
		table,
		th,
		td {
			border-color: currentColor;
		}

		hr {
			background-color: currentColor;
		}

		.btn--primary {
			background-color: $color-dark-primary-sect-btn-bg;
			color: $color-dark-primary-sect-btn-text;
		}

		.btn--secondary {
			background-color: $color-dark-primary-sect-btn-alt-bg;
		}
	}

	.bg-secondary {
		background: $color-dark-secondary-sect-bg;
	}

	.card {
		background-color: $color-dark-card-bg;
		border-color: $color-dark-card-border;

		pre {
			border-color: $color-dark-code-border;
		}

		hr {
			background-color: $color-dark-hr-border;
		}

		table {
			border-color: $color-dark-table-border;
		}

		th,
		td {
			border-color: $color-dark-table-border;
		}

		.btn--primary {
			background-color: $color-btn-bg;
			color: $color-btn-text;
		}

		.btn--secondary {
			background-color: $color-btn-alt-bg;
		}
	}

	&.style-classic {
		.card {
			pre {
				background-color: $color-dark-code-bg;
			}

			th {
				background-color: $color-dark-table-bg;
			}
		}
	}

	&.style-bold {
		.card {
			pre {
				background-color: $color-dark-bold-code-bg;
			}

			th {
				background-color: $color-dark-bold-table-bg;
			}
		}
	}
}

@each $style in map-keys($theme-styles) {
	$style-suffix: "#{$style}";
	$colors: map-deep-get($theme-styles, $style, "colors");
	@each $color, $value in $colors {
		$color-suffix: "#{$color}";

		.style-#{$style-suffix} {
			&.palette-#{$color-suffix} {
				.card {
					.btn--primary {
						background-color: $value;
					}
				}
			}
		}
	}
}
