/**
 * Typography
 */

 /* Text alignment */
.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

/* Headings */
.h1 {
	@extend h1;
}

.h2 {
	@extend h2;
}

.h3 {
	@extend h3;
}

.h4 {
	@extend h4;
}

.h5 {
	@extend h5;
}

.h6 {
	@extend h6;
}