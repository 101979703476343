/**
 * Icons
 */
.icon {
	color: inherit;
	fill: currentColor;
	flex-shrink: 0;
	height: 1em;
	line-height: 1;
	width: 1em;
}

.next-section {
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
	padding-bottom: 2rem;
	text-align: center;
	color: $color-primary;

	&.secondary {
		color: $color-neutral-dark;
	}

	.icon {
		width: 2em;
		height: 2em;
	}
}
