/**
 * Forms
 */
label {
	display: block;
	color: inherit;
	font-size: 0.88889em;
	font-weight: 700;
	line-height: 1.5;
	margin-bottom: 0.3em;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="number"],
input[type="search"],
input[type="url"],
select,
textarea {
	background: $color-input-bg;
	border: 1px solid $color-input-border;
	border-radius: 0;
	box-shadow: none;
	box-sizing: border-box;
	color: $color-input-text;
	display: block;
	font-size: 16px;
	font-weight: normal;
	line-height: 1.5;
	max-width: 100%;
	padding: 0.6875em;
	width: 100%;

	&:focus {
		outline: 0;
	}
}

.form-select {
	color: inherit;
	font-size: 16px;
	position: relative;

	select {
	-webkit-appearance: none;
	-moz-appearance: none;
	padding-right: 25px;

		&:focus {
			outline: 0;
		}
	}

	&:before,
	&:after {
	border: solid transparent;
	border-width: .3125em;
	box-sizing: border-box;
	content: "";
	position: absolute;
	right: 10px;
	top: 50%;
	z-index: 1;
	}

	&:before {
		border-top-color: currentColor;
		margin-top: .125em;
	}

	&:after {
		border-bottom-color: currentColor;
		margin-top: -.75em;
	}
}

.form-checkbox {
	padding-left: 1.5em;
	position: relative;
	
	input[type=checkbox] {
		height: 1.5em;
		left: 0;
		position: absolute;
		top: 0;
	}

	label {
		font-weight: 400;
	}
}

.form-inline {
	label {
		@include sr-only();
	}
}

.style-classic {
	input[type="text"],
	input[type="password"],
	input[type="email"],
	input[type="tel"],
	input[type="number"],
	input[type="search"],
	input[type="url"],
	select,
	textarea {
		background: $color-classic-input-bg;
		border-radius: 6px;
	}
}

.style-bold {
	input[type="text"],
	input[type="password"],
	input[type="email"],
	input[type="tel"],
	input[type="number"],
	input[type="search"],
	input[type="url"],
	select,
	textarea {
		background: $color-bold-input-bg;
		border-color: transparent;
	}
}