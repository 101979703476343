/**
 * Text Block
 */
.text-block {
	p,
	ul,
	ol,
	figure,
	.js-reframe {
		margin-bottom: 1.66667em;
	}

	blockquote {
		font-size: 1.33333em;
		line-height: 1.4;
		margin: 1.66667em 0;
	}

	pre {
		margin: 1.875em 0;
	}

	hr {
		margin: 2.22222em 0;
	}

	blockquote,
	pre,
	hr {
		&:first-child {
			margin-top: 0;
		}
	}
}