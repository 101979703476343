/**
 * Site Footer
 */
.site-footer .grid {
	text-align: center;
	justify-content: center;
}

.site-footer__logo img {
	margin-bottom: 1em;
}

.site-footer__nav,
.site-footer__info {
	border-top: 1px solid $color-footer-border;
}

.site-footer__menu {
	max-width: 270px;
}

.site-footer__copyright,
.site-footer__social {
	text-align: center;
}

.site-footer__copyright {
	font-size: 0.77778em;
}

.site-footer__social {
	.btn {
		margin: 0.83333rem 0.41666rem 0;
		color: $color-primary;
	}
	.icon {
		height: 1.5em;
		width: 1.5em;
	}
}

.site-footer__info .site-footer__social {
	display: none;
}

.site-footer__logo .site-footer__social {
	display: block;
}

@include media-breakpoint-up(md) {
	.site-footer .grid {
		text-align: left;
		justify-content: initial;
	}

	.site-footer__copyright,
	.site-footer__social {
		&:not(:only-child) {
			text-align: left;
		}
	}

	.site-footer__social {
		&:not(:only-child) {
			.btn {
				margin: 0 0 0 1.11111rem;
			}
		}
	}

	.site-footer__logo .site-footer__social {
		display: none;
	}

	.site-footer__info .site-footer__social {
		display: block;
	}
}

.style-bold {
	.site-footer__nav,
	.site-footer__info {
		border-top-width: 2px;
	}
}
