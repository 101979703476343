/**
 * Cover
 */
.has-cover {
	position: relative;
}

.cover-img {
	background-position: center center;
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}