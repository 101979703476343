/**
 * Containers
 */
.container {
	margin-left: auto;
	margin-right: auto;
	max-width: container-max-width(large);
	padding-left: calc($grid-gap);
	padding-right: calc($grid-gap);

	@include media-breakpoint-down(sm) {
		padding-left: calc($grid-gap / 2);
		padding-right: calc($grid-gap / 2);
	}
}

.container--medium {
	max-width: container-max-width(medium);
}
