/**
 * Sections
 */
.section {
	min-height: 100vh;
	padding-top: 99px;
	position: relative;
	box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
	&.has-border {
		border-bottom: 1px solid $color-sect-border;

		&:last-child {
			border-bottom: 0;
		}
	}

	.container {
		position: relative;
	}

	&.bg-none {
		background: none;
	}
	@include media-breakpoint-up(md) {
		padding-top: 109px;
	}
}

.cta {
	padding-top: 15rem;
	margin-top: -135px;
	// z-index: -1;
	min-height: 100vh;
	// min-height: 100%;
	box-sizing: border-box;
	overflow-x: hidden;
	overflow-y: hidden;

	h1 {
		color: $color-primary;
		font-size: 3rem;
	}
	h2 {
		font-family: $body-font;
		color: $color-neutral-light;
		font-size:1.5rem;
	}
	@include media-breakpoint-down(md) {
		margin-top: -55.98px;
	}
}

.hero__media,
.item__media {
	img {
		display: block;
		width: 100%;
	}
}

.hero__title,
.cta__title {
	span {
		border-bottom: 2px solid currentColor;
	}
}

.cta__content {
	padding: 5em 0;
}

.cta__content.logo-outline-overlay::before {
	content: "";
	background: url('../images/pma-logo-outline.svg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	width: 100%;
	height: 100%;
	position: absolute;
	opacity: .2;
	// mix-blend-mode: overlay;
}

#therapies .logo-outline-overlay::before {
	content: "";
	background: url('../images/pma-logo-outline-light.svg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	width: 100%;
	height: 100%;
	position: absolute;
	opacity: .2;
	// mix-blend-mode: overlay;
}

.logo-outline-overlay {
	position: relative;
	text-align: center;
}

.logo-outline-overlay > * {
	z-index: 10;
	position: relative;
}

.logo-outline-overlay::before {
	content: "";
	background: url('../images/pma-logo-outline.svg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	width: 100%;
  height: 100%;
  position: absolute;
	top: 0;
	right: 0;
	z-index: 1;
	// opacity: .5;
	// mix-blend-mode: overlay;
}

.section__title {
	padding: 2em 0;
}

.section__subtitle {
	font-size: 0.88889em;
	margin-bottom: 0.25em;
}

// Hero section
.hero__title {
	padding: 3em 0;
	margin-bottom: 0.25em;
}

.hero__subtitle {
	font-size: 1.22222em;
}

.hero__copy {
	&:not(:first-child) {
		margin-top: 1.11111em;
	}
}

.hero__actions {
	&:not(:first-child) {
		margin-top: 2em;
	}
}

@include media-breakpoint-up(lg) {
	.hero__body {
		font-size: 1.11111rem;
	}
}

// Features section
.feature__title {
 padding: 2em 0;
 position: relative;
 margin-bottom: 1.5em;
 // text-align: center;
}

.feature__subtitle {
	font-size: 1.33333em;
	line-height: 1.25;
}

.feature__actions {
	margin-top: 1.66667em;
	.action {
		margin: .33333em 0;
	}
}

// Feature Map Section
.feature.map {
	#map {
		width: 100%;
		height: 450px;
		border-radius: 25px;
		@include media-breakpoint-down(md) {
			height: 300px;
		}
	}

	.feature__actions .action {
		.btn.btn--clear {
			// margin-bottom: 2em;
			// font-family: $body-font;
			font-size: 1.25em;
			// font-weight: 800;
			width: auto;
		}
		.btn {
			width: 50%;
		}
	}

	@include media-breakpoint-down(md) {
		.feature__body {
			font-size: 125%;
			text-align: center;
			.feature__title {
				padding: 0;
			}
		}
		.feature__actions .action .btn {
			width: 100%;
			&.btn--clear {
				margin-bottom: 1em;
			}
			&:not(.btn--clear) {
				font-size: initial;
			}
		}
	}
}

// Grid section
.item__subtitle {
	font-size: 0.88889em;
}

.item__actions {
	margin-top: 1.66667em;
}

// Blog section
.item__meta,
.item__byline {
	color: $color-meta;
	font-size: 0.77778em;

	a {
		color: inherit;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}

.item__byline {
	margin-top: 1.66667em;
}

.hero + .blog-feed {
	margin-top: -50vh;
}

.blog-feed .item__title {
	margin-bottom: 0.83333em;
}

#inline-form {
	min-height: 50vh;
	padding-top: 50px;
	padding-bottom: 100px;
}

.item__cat + .item__date {
	&:before {
		content: "\00b7\00a0";
		font-weight: 700;
		margin: 0 3px;
	}
}

// CTA section
@include media-breakpoint-up(lg) {
	.cta__copy,
	.cta__actions {
		font-size: 1.11111em;
	}
}

@include media-breakpoint-up(md) {
	.cta__actions {
		&.justify-md-center {
			.btn {
				margin-left: 0.5em;
				margin-right: 0.5em;
			}
		}
	}
}

.style-classic {
	.section__title {
		&:after {
			background-color: $color-classic-sect-underline;
			content: "";
			display: block;
			height: 2px;
			margin: 1.66667rem 0 0;
			width: 5rem;
		}
	}

	.text-center {
		.section__title {
			&:after {
				margin-left: auto;
				margin-right: auto;
			}
		}
	}

	.text-right {
		.section__title {
			&:after {
				margin-left: auto;
			}
		}
	}
}

.style-bold {
	.section {
		&.has-border {
			border-bottom-width: 2px;
		}
	}
}

.bg-primary {
	background: $color-primary-sect-bg;
}

.bg-secondary {
	background: $color-secondary-sect-bg;
}

.bg-neutral-light {
	background: $color-neutral-light-sect-bg;
}

.bg-neutral-dark {
	background: $color-neutral-dark-sect-bg;
}

@each $style in map-keys($theme-styles) {
	$style-suffix: "#{$style}";
	$colors: map-deep-get($theme-styles, $style, "colors");
	@each $color, $value in $colors {
		$color-suffix: "#{$color}";

		.style-#{$style-suffix} {
			&.palette-#{$color-suffix} {
				.bg-primary {
					background-color: $value;
				}
			}
		}
	}
}

.style-classic {
	$classic-colors: map-deep-get($theme-styles, "classic", "colors");
	@each $color, $value in $classic-colors {
		$color-suffix: "#{$color}";

		&.palette-#{$color-suffix} {
			.section__title {
				&:after {
					background-color: $value;
				}
			}
		}
	}

	.bg-primary {
		.section__title {
			&:after {
				background-color: currentColor;
			}
		}
	}
}
