/**
 * Site Layout
 */
.site {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	position: relative;
}

.site-content {
	box-sizing: border-box;
	flex-grow: 1;
	width: 100%;
}

.layout-boxed {
	background-color: $color-boxed-bg;

	.site {
		background-color: $color-body-bg;
		margin-left: auto;
		margin-right: auto;
		max-width: container-max-width(xlarge);
	}

	@media (min-width: 1401px) {
		.site {
			border-left: 1px solid $color-boxed-border;
			border-right: 1px solid $color-boxed-border;
		}

		&.style-bold {
			.site {
				border-left-width: 2px;
				border-right-width: 2px;
			}
		}
	}
}